import React from "react"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import track from "../../components/track"

const Sitemap = () => {

  const pageTitle = 'Site Map';
  const pageDescription = 'Site map';
  const pageName = "Site Map";

  const trackSitemap = trackString => {
    track("patient Site Map page", "link click", "DTC Site Map-" + trackString);
  }

  return (
    <Layout pageTitle={pageTitle} pageDescription={pageDescription} pageType="patient" pageSection="patient-sitemap" pageClass="sitemap"  pageName={pageName}>
      <div className="container">
        <h3>Site map</h3>
        <p><Link to="/" id="patient-sitemap-link-home" onClick={e => trackSitemap("Home")}>Home</Link></p>
        <p><Link to="/about/" id="patient-sitemap-link-about" onClick={e => trackSitemap("About MOVANTIK")}>About MOVANTIK</Link></p>
        <p><Link to="/burden-of-oic/" id="patient-sitemap-link-oic" onClick={e => trackSitemap("Burden of OIC")}>Burden of OIC</Link></p>
        <p><Link to="/dosing/" id="patient-sitemap-link-dosing" onClick={e => trackSitemap("Dosing")}>Dosing</Link></p>
        <p><Link to="/savings/" id="patient-sitemap-link-savings" onClick={e => trackSitemap("Savings")}>Savings</Link></p>

      </div>
    </Layout>
  )
}

export default Sitemap
